<template>
  <van-popup v-model="modalOff" round position="bottom" @close="close">
    <div class="make-c">
      <div class="title">
        <span>预约演示</span>
      </div>
      <van-form class="make-form" @submit="onSubmit" :key="formKey">
        <van-cell-group inset>
          <div class="make-input">
            <div class="name name-must">
              <span>姓名</span>
            </div>
            <van-field
              v-model="formValidate.contactName"
              name="姓名"
              placeholder="请输入联系人姓名"
              :rules="[{ required: true, message: '请输入联系人姓名' }]"
            />
          </div>
          <div class="make-input">
            <div class="name name-must">
              <span>手机号</span>
            </div>
            <van-field
              v-model="formValidate.contactPhone"
              placeholder="请输入联系人手机号"
              :rules="[{ required: true, message: '请输入联系人手机号' }]"
            />
          </div>
          <div class="make-input">
            <div class="name name-must">
              <span>企业名称</span>
            </div>
            <van-field
              v-model="formValidate.fullName"
              placeholder="请输入企业名称"
              :update:model-value="(formValidate.fullName = formValidate.fullName.replace(/\s+/g, ''))"
              :rules="[{ required: true, message: '请输入企业名称' }]"
            />
          </div>
          <div class="make-input">
            <van-field
              v-model="formValidate.describe"
              rows="2"
              autosize
              type="textarea"
              placeholder="请输入想要了解的内容"
            />
          </div>
        </van-cell-group>
        <van-button class="make-btn" round block type="primary" native-type="submit">
          <span>立即预约</span>
        </van-button>
      </van-form>
      <!-- <div class="make-btn" @click="confirm">
      </div> -->
    </div>
  </van-popup>
</template>
<script>
import { customerServiceRecord } from '@/util/api'
import { Toast } from 'vant'
export default {
  props: {
    modalType: {
      type: [Boolean],
      default: false
    }
  },
  data () {
    return {
      modalOff: false,
      formKey: 0,
      formValidate: {
        customerType: '1', // 客户类型
        customerSource: '3', // 客服来源
        contactName: '',
        contactPhone: '',
        fullName: '',
        describe: ''
      },
      formValidateOld: {
        customerType: '1', // 客户类型
        customerSource: '3', // 客服来源
        contactName: '',
        contactPhone: '',
        fullName: '',
        describe: ''
      },
      loading: false,
      agentNameList: []
    }
  },
  watch: {
    modalType (val) {
      this.modalOff = val
      if (!val) {
        this.formKey ++
        this.formValidate = JSON.parse(JSON.stringify(this.formValidateOld))
      }
    }
  },
  methods: {
    onSubmit(v) {
      console.log(v)
      customerServiceRecord(this.formValidate).then(res => {
        console.log(res)
        Toast.success('预约成功');
        this.$emit('modalOff', false)
      })
    },
    // confirm() {
    //   customerServiceRecord(this.formValidate).then(res => {
    //     console.log(res)
    //   })
    // },
    close() {
      this.$emit('modalOff', false)
    },
  }
}
</script>
<style scoped lang="less">
.make-c {
  width: 750px;
  // height: 752px;
  background: #FFFFFF;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin: 45px 0px 50px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      font-size: 36px;
      font-weight: 500;
      color: #3F3F3F;
    }
  }
  // .make-form {
  //   max-height: 50vh;
  //   overflow-y: auto;
  // }
  .make-input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 713px;
    background: #F6F7F9;
    border-radius: 4px;
    margin-bottom: 19px;
    padding: 30px 0px 30px 39px;
    .name {
      width: 200px;
      span {
        font-size: 28px;
        font-weight: 500;
        color: rgba(63, 63, 63, 1);
      }
    }
    .name-must::before {
      content: '*';
      font-size: 28px;
      font-weight: 500;
      color: #B83A3A;
    }
    /deep/.van-cell {
      margin-top: 0;
      overflow: unset;
      height: auto;
      .van-field__body {
        font-size: 28px;
        font-weight: 400;
        color: #B6B6B6;
      }
    }
    /deep/.van-field__control {
      line-height: 1;
    }
    /deep/.van-field__error-message{
      display: none;
    }
  }
  .make-input:last-child {
    margin-bottom: 0px;
  }
  .make-btn {
    margin: 35px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 713px;
    height: 88px;
    background: linear-gradient(90deg, #538AF7, #3471F7);
    border-radius: 4px;
    span {
      font-size: 36px;
      font-weight: 500;
      color: #F6F9FF;
    }
  }
}
</style>
